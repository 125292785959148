@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
    body {
        @apply font-[League+Spartan];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
    
    .section-wrap {
        @apply max-w-[1000px];
        @apply mx-auto;

    }
    a.active {
        @apply border-b-2;
        @apply border-indigo-500;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}